.bgmwzmh{display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;background-color:rgb(var(--background-color-base));min-height:100vh;-webkit-flex-direction:column;-ms-flex-direction:column;flex-direction:column;}.bgmwzmh ul{padding:0;}
.bx7r8ex{display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;-webkit-flex-direction:column;-ms-flex-direction:column;flex-direction:column;position:relative;box-sizing:border-box;will-change:transform,opacity;-webkit-box-pack:space-evenly;-ms-flex-pack:space-evenly;-webkit-justify-content:space-evenly;justify-content:space-evenly;margin:50px auto;-webkit-animation:fadeScaleIn-bx7r8ex 200ms var(--global-ease-out) both;animation:fadeScaleIn-bx7r8ex 200ms var(--global-ease-out) both;}@media only screen and (max-width: 380px){.bx7r8ex{max-width:100vw;min-width:unset;width:320px;}}@-webkit-keyframes fadeScaleIn-bx7r8ex{0%{opacity:0;-webkit-transform:scale(0.8);-moz-transform:scale(0.8);-ms-transform:scale(0.8);transform:scale(0.8);}100%{opacity:1;-webkit-transform:scale(1);-moz-transform:scale(1);-ms-transform:scale(1);transform:scale(1);}}@keyframes fadeScaleIn-bx7r8ex{0%{opacity:0;-webkit-transform:scale(0.8);-moz-transform:scale(0.8);-ms-transform:scale(0.8);transform:scale(0.8);}100%{opacity:1;-webkit-transform:scale(1);-moz-transform:scale(1);-ms-transform:scale(1);transform:scale(1);}}.bx7r8ex .b16am3my{-webkit-align-self:center;-ms-flex-item-align:center;-ms-grid-row-align:center;align-self:center;}
.f133gli{max-width:100vw;}
.l1lec37d{max-width:960px;}
.m9c43r3{max-width:768px;}
.s17ewhl4{max-width:600px;}
.x1nxt7dl{max-width:480px;}
.b4emosk{margin:auto;}
body {color:rgb(var(--text-color-body-01));margin:0;font-family:var(--typography-family-font-family),sans-serif;}a {color:rgb(var(--text-color-body-01));}a:hover {-webkit-text-decoration:underline;text-decoration:underline;}:active ,:hover ,:visited {outline:none;}:focus {outline:var(--button-secondary-border-focus) solid rgb(var(--button-secondary-color-border-focus));outline-offset:-2px;}hr {border:0;width:100%;border-top:1px solid rgba(var(--text-color-body-01), 0.3);}
.badzw9a svg{width:60%;max-width:167px;margin:0 auto;}
.btgxvj6{border-top:1px solid rgba(var(--text-color-body-01), 0.3);padding-top:16px;margin-top:16px;display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;gap:16px;-webkit-box-flex-wrap:wrap;-webkit-flex-wrap:wrap;-ms-flex-wrap:wrap;flex-wrap:wrap;-webkit-box-pack:end;-ms-flex-pack:end;-webkit-justify-content:flex-end;justify-content:flex-end;width:100%;}.btgxvj6 >.b16am3my{margin-right:16px;}.btgxvj6 >.b16am3my:last-child{margin-right:0;}@media (max-width: 480px){.btgxvj6{-webkit-flex-direction:column-reverse;-ms-flex-direction:column-reverse;flex-direction:column-reverse;text-align:center;}.btgxvj6 >.b16am3my{width:100%;margin-right:0;margin-bottom:16px;}.btgxvj6 >.b16am3my >span{width:100%;-webkit-box-pack:center;-ms-flex-pack:center;-webkit-justify-content:center;justify-content:center;}}
.b1v5tngp h2{margin-top:32px;}.b1v5tngp p{margin-top:16px;}
