.b1rasso7{padding:0;}@media only screen and (max-width: 380px){.b1rasso7{max-width:100vw;min-width:unset;width:unset;}}
.bwfpyjg{background-color:rgba(var(--background-color-overlay-alpha));-webkit-backdrop-filter:blur(4px);backdrop-filter:blur(4px);position:-webkit-sticky;position:sticky;bottom:0;padding:24px 0;z-index:1;display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;-webkit-box-pack:center;-ms-flex-pack:center;-webkit-justify-content:center;justify-content:center;-webkit-align-items:center;-webkit-box-align:center;-ms-flex-align:center;align-items:center;gap:16px;max-height:100%;}
.bae25qy{display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;padding:0 48px 0 88px;-webkit-box-flex-wrap:wrap;-webkit-flex-wrap:wrap;-ms-flex-wrap:wrap;flex-wrap:wrap;width:100%;overflow:hidden;box-sizing:border-box;}@media (max-width: 768px){.bae25qy{padding:0 24px 0 24px;}}
.b4dmgvo a{width:100%;}
.b5rxpt2{display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;-webkit-align-items:center;-webkit-box-align:center;-ms-flex-align:center;align-items:center;gap:16px;}
.b1nzxnqs .b16am3my{background:rgb(50, 241, 241, 1);background-image:linear-gradient(180deg, rgb(50, 241, 241, 1) 0%, rgb(50, 241, 241, 0) 80%),repeating-linear-gradient(
					45deg,
					rgb(120, 120, 120, 0.2),
					rgb(120, 120, 120, 0.2) 1.4rem,
					transparent 1.4rem,
					transparent 2.8rem
				);border-width:2px;border-style:solid;border-top-width:0;border-left-width:0;border-right-width:0;box-shadow:inset 0 0 0 2px rgb(50, 241, 241, 1);border-color:rgb(40, 191, 191, 1);fill:#e25047!important;color:#320064!important;}.b1nzxnqs .b16am3my:hover{background:rgb(255, 255, 255, 1)!important;background-image:linear-gradient(180deg, rgb(255, 255, 255, 1) 0%, rgb(255, 255, 255, 0) 80%),repeating-linear-gradient(
						45deg,
						rgb(120, 120, 120, 0.2),
						rgb(120, 120, 120, 0.2) 1.4rem,
						transparent 1.4rem,
						transparent 2.8rem
					)!important;box-shadow:inset 0 0 0 2px rgb(255, 255, 255, 1);}.b1nzxnqs .b16am3my:hover path{fill:#e25047!important;}@media (max-width: 600px){.b1nzxnqs .b16am3my{width:100%;-webkit-box-pack:center;-ms-flex-pack:center;-webkit-justify-content:center;justify-content:center;}}
.bk13t8b{position:relative;}.bk13t8b ul{max-height:320px;}
